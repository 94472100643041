import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import { Accordion,Card, } from 'react-bootstrap';
	
const defaultAccordion1 = [
	{
		title: 'How is discipline encouraged at Le Dauphinz ?',
		text: "Our trained teachers and expert staff follow child-centric measures, to be proactive and find solutions to disciplinary issues along with the children. No corporal punishment is allowed. A balanced and structured curriculum results in timely conditioning of the child. By keeping the child constructively engaged in play activities and collaborative group work, our teachers are able to instil discipline easily "
	},
	{
		title: ' What is the medium of instruction at Le Dauphinz ?',
		text: 'The medium of instruction at Le Dauphinz  PreschoolisEnglish.',
	},
	{
		title: 'What are the languages taught at Le Dauphinz?',
		text: 'At Le Dauphinz, the languages taught and spoken are English, along with Hindi/Regional language to make the children feel comfortable and enable them to communicate with ease both at school, at home and elsewhere',
	},
	{
		title: 'How can parents learn more about Le Dauphinz?',
		text: 'Parents can experience the centre personally, by taking a guided tour with the facilitator in-charge. This will help them better understand the facilities and methods of teaching. We have a Parent Orientation program to familiarize the parents with curriculum and learning outcomes. We also conduct Parents’ Workshops, to work hand-in-hand with parents towards shaping their child’s future.',
	},
	{
		title: 'What are the preschool operating hours?',
		text: 'The timings are 8:45 AM to 11:15 AM For Morning Batch 11:30 AM to 2:00 PM for Noon Batch for Play Group & Nursery. 8:45 AM to 12:45 PM for LKG & UKG For further details, please visit the Le Dauphinz Preschool.',
	},
		
]	
const AccordionBlog = () => {
	const [activeDefault, setActiveDefault] = useState(0)
	return(
		<Accordion className="accordion faq-box"  defaultActiveKey="-1">
			{defaultAccordion1.map((d, i) => (
			   <Card >
					<div className="card-header ">
						<Accordion.Toggle as={Link} variant="link"
							className={` ${ activeDefault === i ? '' : 'collapsed'}`}
							onClick={() =>
								setActiveDefault(activeDefault === i ? -1 : i)
							} eventKey={`${i}`}>	
							 {d.title}		
						</Accordion.Toggle>
					</div>
				<Accordion.Collapse eventKey={`${i}`} className="">
				  <Card.Body >
					{d.text}
					</Card.Body>
				</Accordion.Collapse>
			  </Card>
			))}
		</Accordion>	
	)
}
export {defaultAccordion1};
export default AccordionBlog;