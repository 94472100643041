import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
import bnr1 from './../../images/line2.png';
import team1 from './../../images/team/pic1.jpg';


const teamBlog = [
	{image: team1 ,},
];

class Teachers extends Component{
	render(){
		return(
			<Fragment>
				<Header />
					<div className="page-content">
						<PageTitle motherMenu="Teachers" activeMenu="Teachers" />
						<div className="content-block">
							<div className="section-full bg-white content-inner" style={{backgroundImage:"", backgroundSize:"contain", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
								<div className="container">
									<div className="section-head text-center">
										<h2 className="head-title text-secondry">About the Teachers</h2>
										<p>
											We have an excellent teacher to child ratio at our PreSchool to ensure that each child receives the attention he or she needs
										</p>
									</div>
									<div className="row">
										{/* {teamBlog.map((data,index)=>(
											<div className="col-lg-3 col-md-6 col-sm-6" key={index}>
												 <div className="dlab-box box-frame1 team-box m-b40">
													<div className="dlab-thum"> 
														<Link to={"/teachers-details"}>
															<img src={data.image} alt="" />
														</Link>
														<div className="overlay-bx">
															<h5 className="team-title"><Link to={"/teachers-details"}>Jone Doe</Link></h5>
															<span className="team-info">Teachers</span>
															<ul className="list-inline">
																<li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-google-plus"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-instagram"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										))} */}
									
									<div>
										<img src={team1} alt="" />
										<div className="section-head m-t20">
											<p>
												We at Le Dauphinz Pre School believe that teachers are the pillars of a school. A great teacher makes a tremendous impact on a child's mind. They are responsible for cultivating curiosity and wonder in the minds of children, and making sure that every child receives the nurturing and inspiration they deserve.  Our teachers are qualified in early childhood education and competent in nurturing children who are at an impressionable age. Each teacher knows the important role they play in encouraging a child’s zest for learning. That's why the role of a teacher at Le Dauphinz  goes much beyond just 'instruction'. A combination of patience, compassion and knowledge helps each teacher cater to individual learning needs, help children to reach their full potential. They are geared to help them achieve learning goals through group and individual tasks. Faculty at Le Dauphinz  Preschool is encouraged to weave their own creative methods and ideas into the curriculum, when it comes to helping children learn. Our teachers create a safe and stimulating environment for learning.
											</p>
										</div>
									</div>
									
									</div>	
								</div>
							</div>
						</div>
					</div>
				<Footer />
			</Fragment>
		)
	}
}

export default Teachers;