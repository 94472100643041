import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import largeimg from './../../images/blog/large/pic1.jpg';
import largeimg1 from './../../images/blog/large/pic1.jpg';
import largeimg2 from './../../images/blog/large/pic2.jpg';
import largeimg3 from './../../images/blog/large/pic3.jpg';
import largeimg4 from './../../images/blog/large/pic4.jpg';


class FeatureList extends Component {
    render() {
        return (
            <Fragment>
                <div className="classes-details">
                    <div className="class-media">
                        <img src={largeimg1} alt="" />
                    </div>
                    <div className="class-info">
                        <div className="dlab-post-title ">
                            <h2 className="post-title m-t0">Creative Learning</h2>
                        </div>

                        <div className="dlab-post-text">
                            <p>
                                The child is exposed to diverse activities such as Arts and Craft, Sports, Sand Play, Water Play, Dancing & Role Play, Audio Visual Shows, Library Time, gardening, Toys & Books, Field Trips and many more.
                            </p>

                        </div>

                    </div>
                    <div className="class-info">
                        <div className="dlab-post-title ">
                            <h2 className="post-title m-t0">Our Integrated Curriculum</h2>
                        </div>

                        <div className="dlab-post-text">
                            <p>
                                An integrated curriculum allows children to pursue learning in a holistic way, without the restrictions often imposed by subject boundaries. In early childhood programs it focuses upon the inter-relatedness of all curricular areas in helping children acquire basic learning tools. 
                            </p>

                            <blockquote>
                                Integrated teaching and learning processes enable children to acquire and use basic skills in all the content areas and to develop positive attitudes for continued successful learning throughout the elementary grades.
                            </blockquote>

                            {/* <p>
                                It recognizes that the curriculum for the primary grades includes reading, writing, listening, speaking, literature, drama, social studies, math, science, health, physical education, music, and visual arts. 
                            </p> */}
                            
                        </div>

                    </div>
                </div>

                <div className="classes-details">
                    <div className="class-media">
                        <img src={largeimg2} alt="" />
                    </div>
                    <div className="class-info">
                        <div className="dlab-post-title ">
                            <h2 className="post-title m-t0">Best Learning Environment</h2>
                        </div>

                        <div className="dlab-post-text">
                           
                            <h4>Largest Play Area</h4>
                            <p>
                                Outdoor play is critical for young children in our early childhood programs and schools. First, many of the developmental tasks that children must achieveexploring, fine and gross motor development and the absorption of vast amounts of basic knowledgecan be most effectively learned through outdoor play. outdoor play is critical for the healthy development of young children.
                            </p>

                            <h4>Large Class rooms</h4>
                            <p>
                                We believe that children need space to grow and develop in our large free flowing classrooms.
                            </p>

                            <h4>Interactive Digital Class rooms</h4>
                            <p>
                                Our Smart Classroom solution is based on interactive, rich multimedia content-enabled education system which illuminates abstract & concepts with clarity. It bridges the learning gaps between teacher & students and helps them realize their true potential.
                            </p>

                            <h4>Splash Area</h4>
                            <p>
                                It is not just kids love playing in water but helps children develop resilience and stamina.
                            </p>

                            <h4>Sensory Zone</h4>
                            <p>
                                We all know sensory exploration is important to a developing child. Kids learn so much through touch and sense in relation to how their bodies work in the environment they are in. There are lots of ideas for touch and play with your hands, but lately we’ve been seeing an emphasis in sensory walks. Walking barefoot and exploring your feet’s reaction to different textures is not only fun, but instrumental in promoting an acute awareness of your senses and surroundings.
                            </p>

                            <h4>Sensory Sound Wall</h4>
                            <p>
                                An accessible and interactive way to make music, the Sound Wall provides children of all abilities with a great way to explore sound and develop cause and effect skills in a fun and unusual way.
                                <p></p>
                                An accessible and interactive way to make music, the Sound Wall provides children of all abilities with a great way to explore sound and develop cause and effect skills in a fun and unusual way.
                            </p>

                            <h4>Our Kids Garden</h4>
                            <p>
                                Gardens can be a great place to cultivate a meaningful and fun learning experience for children. It’s a natural match. Gardening can offer children an opportunity to learn the life cycle process, by which plants are grown, as well as responsibility, caretaking, independence, and environmental awareness. Introducing children to gardening is a great way to increase their awareness of where food comes from and the importance of the environment in everyday life. 
                            </p>

                            <h4>Junior Kitchen </h4>
                            <p>
                                Cooking with Kids,will get the little ones involved in the kitchen with simple, kid-friendly recipes and ideas.
                            </p>

                        </div>

                    </div>
                </div>

                <div className="classes-details">
                    <div className="class-media">
                        <img src={largeimg3} alt="" />
                    </div>
                    <div className="class-info">
                        <div className="dlab-post-title ">
                            <h2 className="post-title m-t0">Safety Security and Hygiene</h2>
                        </div>
                        <p>
                            We provide children with much more than a safe and secure environment. Here each child feels comfortable to explore, discover, challenge and understand their physical worlds without fear. To us a child’s health & safety are of prime importance, with CC TV surveillance in all areas, Child Safe Interiors and Furniture,Hygienic Premises with fumigation and regular pest controls, 100% Female staff, Child Friendly Toilets and First Aid Kits.
                        </p>

                    </div>
                </div>
                <div className="classes-details">
                    <div className="class-media">
                        <img src={largeimg4} alt="" />
                    </div>
                    <div className="class-info">
                        <div className="dlab-post-title ">
                            <h2 className="post-title m-t0">Teachers Facilitators</h2>
                        </div>

                        <div className="dlab-post-text">
                            <p>
                                We take great pride in the exceptional quality of our professionally qualified and experienced teachers. Each and every teacher is selected through a rigorous recruitment process. Our teachers undergo various teacher training sessions and general development initiatives.
                            </p>
                            <blockquote>
                                Our teachers plan intentionally. They establish specific goals for their children,as well as evaluate paths for inquiry based learning. They make decisions about learning experiences, resources, design and location of the learning environment, routines, group interactions and strategies for assessment.Our teachers are lifelong learners themselves. Continuously guided, coached and encouraged by school management.
                            </blockquote>

                        </div>

                    </div>
                </div>

            </Fragment>
        )
    }
}
export default FeatureList;