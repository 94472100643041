import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom'; 
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
import Masonry from 'react-masonry-component';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper, useLightbox} from 'simple-react-lightbox'; 
import AccordionBlog from '../Element/AccordionBlog';
import TestiMonialSlider from '../Element/TestiMonialSlider';
import VideoPopup from '../Element/VideoPopup';
//Images
import bnr1 from './../../images/line2.png';
import bnr2 from './../../images/background/bg5.jpg';
import bgimg2 from './../../images/background/bg1.jpg';
import bnr4 from './../../images/line2.png';
import about from './../../images/about/about-1.jpg';
import about1 from './../../images/about/about-2.jpg';
import gallery1 from './../../images/gallery/pic1.jpg';
import gallery2 from './../../images/gallery/pic2.jpg';
import gallery3 from './../../images/gallery/pic3.jpg';
import gallery4 from './../../images/gallery/pic4.jpg';
import gallery5 from './../../images/gallery/pic5.jpg';
import gallery6 from './../../images/gallery/pic6.jpg';

// Masonry section
const masonryOptions = {                                          
    transitionDuration: 0
};
const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end

//Light Gallery Icon
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
		<Link to={"#"}
			onClick={(e) => {
				e.preventDefault()
				openLightbox(props.imageToOpen)
			}} className="check-km" >
			<i className="fa fa-search icon-bx-xs"></i>
		</Link>
	)

}
const galleryBlog = [
	{image: gallery1},	
	{image: gallery2},	
	{image: gallery3},	
	{image: gallery4},	
	{image: gallery5},	
	{image: gallery6},	
];

function BackgroundBlog(){
	return(
		<>
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-12  about-text"  style={{visibility: "visible", animationDuration: "2s", animationDelay: "0.2s", animationName: "fadeIn"}}>
						<div className="section-head text-center">
							<div className="video-play">
								{/* <VideoPopup /> */}
							</div>
							<h2>Let Your Kids Have an Amazing<br/>Time at the Park</h2>
							<Link to={"./contect-us"} className="btn btn-md radius-xl">Read More</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
function EducationBanner() {
	return (
		<>
			<div className="section-full bg-white content-inner-2 about-content bg-img-fix" style={{ backgroundImage: "url(" + bgimg2 + ")" }}>
				<div className="about-overlay-box"></div>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="section-head">
								<h2 className="head-title text-yellow">Education from birth<br />begins with us</h2>
								<p className="text-white">Le Dauphinz Preschool a perfect place with an environment that encourages active learning through methods that invoke and stimulate the child's potential, research shows that by an active participation of the child in various activities, we can ensure growth in the intellectual capabilities of the child.</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-12"></div>
					</div>
				</div>
			</div>
		</>
	)
}

class AboutUs1 extends Component{
	componentDidMount(){
		var splitBox = document.querySelectorAll('.split-box');
       
		var fSB = [].slice.call(splitBox);
		
		fSB.forEach(el => el.classList.add('split-active'));
	}
	render(){
		return(
			<Fragment>
				<Header />	
				 <div className="page-content">
					<PageTitle  motherMenu="About Us"  activeMenu="About Us" />
					<div className="content-block">
						<div className="section-full bg-white content-inner-2 about-area" style={{backgroundImage:"url("+ bnr1 +")", backgroundSize:"contain", backgroundRepeat: "no-repeat",backgroundPosition: "center"}}>
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-md-12 col-sm-12 m-b15">
										<div className="section-head">
											<h2 className="head-title text-secondry">About the Founder</h2>
											<p>
												Mrs Lakshmi Prasanna is a Educationist and an Enthusiastic Entrepreneur. She has over 15 years of experience in early childhood education, as Teacher, Curriculum Developer and Administrator. Her views on working with young minds are radical, progressive and dynamic.
												<p></p>
												Mrs Lakshmi Prasanna considers each child as an individual. She is passionate about unearthing and cultivating a child’s potential through creative, stimulating and exclusive channels. She has spent a large part of her career evolving unrivalled curriculums to foster the individual needs of a child to mould him/her into an exclusive person.
												<p></p>
												In this way, she aims to be the guiding force for thousands of children with distinguished determination and effectuation. Her eminent standards of excellence has ensured that Le Dauphinz Preschool is graded amongst the best pre-schools in India.
											</p>
											</div>
									
										{/* <AccordionBlog /> */}
									</div>
									<div className="col-lg-6 col-md-12 col-sm-12 teacher-content">
										<div className="split-box">
											<div className="about-media">
												<img src={about} alt=""/>
											</div>
										</div>
									</div>


									<div className="col-lg-6 col-md-12 col-sm-12 m-t40 teacher-content">
										<div className="split-box">
											<div className="about-media">
												<img src={about1} alt="" />
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-md-12 col-sm-12 m-t40">
									<div className="section-head">
										<h2 className="head-title text-secondry">From Founder's Desk</h2>
											<p>
												In today’s fast paced life and an intensely competitive world, qualitative education is indispensable for equipping kids for future eventualities. With an ever increasing population and the limited no of seats available in higher education, the modern-age parents are becoming increasingly conscious about imparting an extra edge to their kid’s learning process, and that too at an early stage. The first five years of a child’s life are most crucial. It’s at this stage that future foundations are laid, vital skills are learnt, values are ingrained and attitudes are formed. This fact is now understood by all parents, who seek top-notch all-round learning institutes for their young wards, before sending them to regular school.
											<p></p>

												At Le Dauphinz, we believe that this increased consciousness of new-age parents, about giving best grooming and learning experience to their kids at an early stage, is our first & foremost a serious social obligation, Promoted by Ganasai Educations trust, Le Dauphinz is the sheer vision, hard work, consistency and grit of its founders, Mr. Viswapal and Mrs. Lakshmi Prasanna has enabled Le Dauphinz to achieve its current status.
											
										</p>
										</div>
									</div>
									<div className="section-head">
										<p>
											The pre-school boasts of a strong curriculum and a high focus rendered to other play based extracurricular activities referred to as Enrichment and Development Programs which are designed to inculcate the all important life-skills to ensure that children attain skills and abilities that will benefit them through their lives.
										<p></p>

											At Le Dauphinz, we’ve created an intellectually stimulating and fun-filled atmosphere, to nurture critical life-skills in kids. we boast of the lowest student to teacher ratio that maximizes teacher attention on an individual kid and fosters his self-learning process. Through a stress free, positive and eco-friendly environment, we build a strong foundation in the formative years of kids, so as to prepare independent & self-motivated individuals for future. With an extensive experience of its founders, remarkable operational consistency and credible & extensive networking, LE DAUPHINZ is strongly positioned in the educational domain. With our unflinching dedication and sincere efforts, we have created a distinct heritage that we plan to build further through our progressive expansion strategies.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="section-full bg-white content-inner-2 about-content bg-img-fix" style={{backgroundImage:"url(" + bnr2 + ")"}}>
							<BackgroundBlog />
						</div>
						{/*  Portfolio  */}
						<div className="section-full content-inner">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Gallery</h2>
									{/* <p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p> */}
								</div>
								<SimpleReactLightbox>
									<SRLWrapper>
										<div className="clearfix" id="lightgallery">
											<ul id="masonry" className="dlab-gallery-listing gallery-grid-4 gallery">
												<Masonry
													className={'my-gallery-class'} 
													options={masonryOptions} 
													disableImagesLoaded={false} 
													updateOnEachImageLoad={false} 
													imagesLoadedOptions={imagesLoadedOptions} // default {}
												>
													{galleryBlog.map((data,index)=>(
														<li className="web design card-container col-lg-4 col-md-6 col-sm-6 "  key={index}>
															<div className="dlab-box frame-box m-b30">
																<div className="dlab-thum dlab-img-overlay1"> 
																	<img src={data.image} alt="" />
																	<div className="overlay-bx">
																		<div className="overlay-icon"> 
																			<Iconimage imageToOpen={index} /> 
																		</div>
																	</div>
																</div>
															</div>
														</li>
													))}
												</Masonry>		
											</ul>
										</div>
									</SRLWrapper>	
								</SimpleReactLightbox>	
							</div>
						</div>
						<EducationBanner />
						{/* <div className="section-full bg-white content-inner-2" style={{backgroundImage:"url(" + bnr4 +")", backgroundSize:"contain",backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Testimonials about center</h2>
									<p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p>
								</div>
								<TestiMonialSlider />
							</div>
						</div> */}
					</div>
				</div>	
				<Footer />	
			</Fragment>
		)
	}
}
export { BackgroundBlog, EducationBanner };
export default AboutUs1;