import React,{Component} from 'react';
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox'; 
import {SRLWrapper, useLightbox} from 'simple-react-lightbox';

import gallery1 from './../../images/gallery/pic1.jpg';
import gallery2 from './../../images/gallery/pic2.jpg';
import gallery3 from './../../images/gallery/pic3.jpg';
import gallery4 from './../../images/gallery/pic4.jpg';
import gallery5 from './../../images/gallery/pic5.jpg';
import gallery6 from './../../images/gallery/pic6.jpg';
import gallery7 from './../../images/gallery/pic7.jpg';
import gallery8 from './../../images/gallery/pic8.jpg';
import gallery9 from './../../images/gallery/pic9.jpg';
import gallery10 from './../../images/gallery/pic10.jpg';
import gallery11 from './../../images/gallery/pic11.jpg';
import gallery12 from './../../images/gallery/pic12.jpg';
import gallery13 from './../../images/gallery/pic13.jpg';
import gallery14 from './../../images/gallery/pic14.jpg';
import gallery15 from './../../images/gallery/pic15.jpg';
import gallery16 from './../../images/gallery/pic16.jpg';
import gallery17 from './../../images/gallery/pic17.jpg';
import gallery18 from './../../images/gallery/pic18.jpg';
import gallery19 from './../../images/gallery/pic19.jpg';
import gallery20 from './../../images/gallery/pic20.jpg';
import gallery21 from './../../images/gallery/pic21.jpg';
import gallery22 from './../../images/gallery/pic22.jpg';
import gallery23 from './../../images/gallery/pic23.jpg';
import gallery24 from './../../images/gallery/pic24.jpg';
import gallery25 from './../../images/gallery/pic25.jpg';
import gallery26 from './../../images/gallery/pic26.jpg';
import gallery27 from './../../images/gallery/pic27.jpg';
import gallery28 from './../../images/gallery/pic28.jpg';
import gallery29 from './../../images/gallery/pic29.jpg';
import gallery30 from './../../images/gallery/pic30.jpg';
import gallery31 from './../../images/gallery/pic31.jpg';
import gallery32 from './../../images/gallery/pic32.jpg';
import gallery33 from './../../images/gallery/pic33.jpg';
import gallery34 from './../../images/gallery/pic34.jpg';
import gallery35 from './../../images/gallery/pic35.jpg';
import gallery36 from './../../images/gallery/pic36.jpg';
import gallery37 from './../../images/gallery/pic37.jpg';
import gallery38 from './../../images/gallery/pic38.jpg';
import gallery39 from './../../images/gallery/pic39.jpg';
import gallery40 from './../../images/gallery/pic40.jpg';
import gallery41 from './../../images/gallery/pic41.jpg';
import gallery42 from './../../images/gallery/pic42.jpg';
import gallery43 from './../../images/gallery/pic43.jpg';
import gallery44 from './../../images/gallery/pic44.jpg';
import gallery45 from './../../images/gallery/pic45.jpg';

//Light Gallery on icon click 
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
		<Link to={"#"}
			onClick={(e) => {
				e.preventDefault()
				openLightbox(props.imageToOpen)
			}} className="check-km" >
			<i className="fa fa-search icon-bx-xs"></i>
		</Link>
	)

}

const latestBlog = [
	{ images: gallery1 }, { images: gallery2 }, { images: gallery3 },
	{ images: gallery4 }, { images: gallery5 }, { images: gallery6 },
	{ images: gallery7 }, { images: gallery8 }, { images: gallery9 },
	{ images: gallery10 }, { images: gallery11 }, { images: gallery12 },
	{ images: gallery13 }, { images: gallery14 }, { images: gallery15 },
	{ images: gallery16 }, { images: gallery17 }, { images: gallery18 },
	{ images: gallery19 }, { images: gallery20 }, { images: gallery21 },
	{ images: gallery22 }, { images: gallery23 }, { images: gallery24 },
	{ images: gallery25 }, { images: gallery26 }, { images: gallery27 },
	{ images: gallery28 }, { images: gallery29 }, { images: gallery30 },
	{ images: gallery31 }, { images: gallery32 }, { images: gallery33 },
	{ images: gallery34 }, { images: gallery35 }, { images: gallery36 },
	{ images: gallery37 }, { images: gallery38 }, { images: gallery39 },
	{ images: gallery40 }, { images: gallery41 }, { images: gallery42 },
	{ images: gallery43 }, { images: gallery44 }, { images: gallery45 },
]
class GallerySlider extends Component{	
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}
	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}
	render(){
		var settings = {		
			arrows: false,
            slidesToShow: 3,			
            infinite: true,
			autoplay: true,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
				  }
				}
			]
        };
		return(
			<>	
				<SimpleReactLightbox>
					<SRLWrapper >
						<div className="sprite-nav">
							<Slider className="gallery-carousel owl-carousel owl-theme dots-none " ref={c => (this.slider = c)} {...settings}>
								{latestBlog.map((item, index) => (
									<div className="item p-3" key={index}>
									 <div className="dlab-box frame-box">
										<div className="dlab-thum dlab-img-overlay1"> 
												<img src={item.images} alt="" />
											<div className="overlay-bx">
												<div className="overlay-icon"> 
														<Iconimage imageToOpen={index} /> 
												</div>
											</div>
										</div>
									</div>
								</div>
								))}	
							</Slider>	
							<div className="owl-nav" style={{ textAlign: "center" }}>
								<div className=" owl-prev " onClick={this.previous}/>
								<div className="owl-next " onClick={this.next}/>
							</div>
						</div>	
					</SRLWrapper>
				</SimpleReactLightbox>	
			</>	
		)
	}
}
export default GallerySlider;