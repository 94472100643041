import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
import ClassDetailContent from '../Element/ClassDetailContent';

//images
import bnr1 from './../../images/line2.png';
import recent1 from './../../images/blog/recent-blog/pic1.jpg';
import recent2 from './../../images/blog/recent-blog/pic2.jpg';
import gallery1 from './../../images/gallery/pic1.jpg';
import gallery2 from './../../images/gallery/pic2.jpg';
import gallery3 from './../../images/gallery/pic3.jpg';
import gallery4 from './../../images/gallery/pic4.jpg';
import gallery5 from './../../images/gallery/pic5.jpg';
import gallery7 from './../../images/gallery/pic7.jpg';
import gallery8 from './../../images/gallery/pic8.jpg';
import gallery9 from './../../images/gallery/pic9.jpg';
import FeatureList from '../Element/FeatureList';

function PostMedia() {
    return (
        <>
            <div className="dlab-post-meta">
                <ul>
                    <li className="post-author">By Jone</li>
                    <li className="post-comment"><i className="fa fa-comments"></i> 28</li>
                </ul>
            </div>

        </>
    )
}
function LightGallery() {
    return (
        <>
            <SimpleReactLightbox>
                <SRLWrapper>
                    {galleryBlog.map((data, index) => (
                        <li key={index}>
                            <span className="check-km"><img src={data.image} alt="" /></span>
                        </li>
                    ))}
                </SRLWrapper>
            </SimpleReactLightbox>
        </>
    )
}
const galleryBlog = [
    { image: gallery1, },
    { image: gallery5, },
    { image: gallery7, },
    { image: gallery8, },
    { image: gallery2, },
    { image: gallery3, },
    { image: gallery4, },
    { image: gallery9, },
];

class ClassesDetail extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <div className="page-content">
                    <PageTitle motherMenu="Features" activeMenu="Features" />
                    <div className="content-block">
                        <div className="section-full bg-white content-inner" style={{ backgroundImage: "url(" + bnr1 + ")", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                                        <FeatureList />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}

export { LightGallery }
export default ClassesDetail;