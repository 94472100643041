import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import { Accordion,Card, } from 'react-bootstrap';
	
const defaultAccordion = [
	{
		title: 'What are the preschool programs that are offered by Le Dauphinz ?',
		text: "Le Dauphinz offers well-defined and age-appropriate preschool programs as follows: Playing Pods   / Playgroup - Program for Age: 2 - 3 years. Learning Pods / Nursery - Program for Age: 3 - 4 years. Junior Pods / L KG - Program for Age: 4 - 5 years. Senior Pods / UKG - Program for Age: 5 - 6 years "
	},
	{
		title: 'Why should I choose Le Dauphinz Preschool for my child?',
	  text: 'Le Dauphinz, is the best preschool in your neighbourhood started in the year 2008 byGanasai Educations.Le DauphinzPre school has the largest play area of 15,000. Sq.ft, Owing to our scientifically researched and result-oriented approach, committed offerings and strong value proposition.',
	},
	{
		title: 'What is the admission process at Le Dauphinz ?',
	  text: 'The preschool admission process  at Le Dauphinz is straight forward and transparent. Parents may visit the centre and have a word with our admissions counsellor. Parents are encouraged to take a guided tour of the preschool with the counsellor. This would help them understand the facilities at our centre and experience the environment where we nurture young minds,  and Fill in the admission form.',
	},
	{
		title: 'Does Le Dauphinz  admit children during mid-year in a preschool program?',
	  text: 'Yes, children can be admitted during mid-year for Paying PODS (Playgroup) and Learning Pods (Nursery) preschool program only.',
	},
	{
		title: 'What is the student-teacher ratio at Le Dauphinz ?',
	  text: 'The student-teacher ratio helps to evaluate the quality of personal attention that teachers can provide to children. we follow a student teacher ratio of 8:1 for Play Group, 10:1 for Nursery and 12:1 for LKG & UKG.',
	},
	{
		title: 'When does the academic session commence at Le Dauphinz?',
		text: 'The academic sessions for the academic term cycle June - March. For further details, please visit Le Dauphinz.',
	},
		
]	
const AccordionBlog = () => {
	const [activeDefault, setActiveDefault] = useState(0)
	return(
		<Accordion className="accordion faq-box"  defaultActiveKey="-1">
			{defaultAccordion.map((d, i) => (
			   <Card >
					<div className="card-header ">
						<Accordion.Toggle as={Link} variant="link"
							className={` ${ activeDefault === i ? '' : 'collapsed'}`}
							onClick={() =>
								setActiveDefault(activeDefault === i ? -1 : i)
							} eventKey={`${i}`}>	
							 {d.title}		
						</Accordion.Toggle>
					</div>
				<Accordion.Collapse eventKey={`${i}`} className="">
				  <Card.Body >
					{d.text}
					</Card.Body>
				</Accordion.Collapse>
			  </Card>
			))}
		</Accordion>	
	)
}
export {defaultAccordion};
export default AccordionBlog;