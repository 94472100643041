import React,{Component} from 'react';
import Slider from "react-slick";
import grid1 from './../../images/testimonials/pic2.jpg';


const latestBlog = [
	{ image: grid1, name: "Swati Sharma", childName: "Mother of Advaith Sharma, Nursery ", desc: "I find the teaching methods at Le Dauphinz very encouraging.My child’s development was more than what I expected, across all areas - intellectual, communication & extracurricular activities. They have a huge play area for the children which is lacking in many schools. ", },
	{ image: grid1, name: "Sushma ", childName: "Mother of Riddhi, UKG ", desc: "Le Dauphinz Preschool has been an awesome experience for us and Riddhi. The environment provided for children is great. A lot of effort has been made to make sure small things are taken care of. Staff is very motivated and love children which is very important for us as parents. A special mention about the curriculum which helped Riddhiin Grade I.Thanks a ton ", },
	{ image: grid1, name: "Dr. Ananth ", childName: "Father of Ruthvika.A ", desc: "The school's mission to “Learning for life” is seen in action every passing day; this is what sets you, folks, apart,  A safe environment giving each of the little minds a multitude of opportunities to be creative, collaborative, while primarily focusing on their individual personalities. One of the very few schools which believes and works at building a strong partnership with parents. Thank you Le Dauphinz Pre-School Team. ", },
	{ image: grid1, name: "Ritesh Nair ", childName: " Father of NishaNair, Play Group ", desc: "My experience has been great and my daughter enjoys a lot.She looks forward to go to school daily And enjoys all the activities and games. ", },
	{ image: grid1, name: "Shanthi Ramakrishna ", childName: "Mother of Kavitha Ramakrishna ", desc: "It is a really wonderful experience. My child has learned many new things like comparing and managing things on her own. Activities are very informative and engaging. Teachers have formed a very loving relationship with my daughter in fact, she misses her teachers at home. ", },
	{ image: grid1, name: "Sreeram Reddy ", childName: "Father of Vivaan Reddy ", desc: "The teachers and staff is really appreciative of the kids,.helping, caring and understanding. They were a strong support system for Vivaans initial stages at school. They are always there to explain him things that were necessary for him to w. Thank you so very much. ", },
	{ image: grid1, name: "Mrs & Mr Prasad ", childName: "Parents of Siddharth & Sudhiksha ", desc: "We have been a part of the Le Dauphinz  family for over 8 years, two  of our children have attended since they were each 2 years old. Le Dauphinz Preschool is that place where you head off for your day knowing that your child will be happy, safe and loved. Our son entered 1st grade  school well educated and over prepared. They look back on their preschool days fondly. Our youngest child enters her classroom each and every day, giggling and running into her teacher’s arms. Dauphinz  Preschool is that place you feel great to bring your child to. It’s that place where everyone knows your name. ", },
]
class TestiMonialSlider extends Component{	
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}
	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}
	render(){
		var settings = {		
			arrows: false,
            slidesToShow: 1,			
            infinite: true,
			autoplay: true,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 1,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 1,
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
				  }
				}
			]
        };
		return(
			<>	
				<div className="sprite-nav">
					<Slider className="client-carousel owl-carousel owl-theme  " ref={c => (this.slider = c)} {...settings}>
						{latestBlog.map((item, index) => (
							<div className="item" key={index}>
								<div className="client-box">
									<div className="testimonial-detail clearfix">
										<div className="testimonial-pic radius">
											<img src={item.image} width="100" height="100" alt="" />
										</div>
										<h5 className="testimonial-name m-t0 m-b5">{item.name}</h5> 
										<span>{item.childName}</span> 
									</div>
									<div className="testimonial-text">
										<p>{item.desc}</p>
									</div>
								</div>	
							</div>
						))}	
					</Slider>		
					<div className="owl-nav" style={{ textAlign: "center" }}>
						<div className=" owl-prev " onClick={this.previous}/>
						<div className="owl-next " onClick={this.next}/>
					</div>
				</div>	
			</>	
		)
	}
}
export default TestiMonialSlider;