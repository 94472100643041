import React,{Fragment,Component} from 'react';
import {Link} from 'react-router-dom';
import SimpleReactLightbox,{SRLWrapper} from 'simple-react-lightbox'; 
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
import ClassDetailContent from '../Element/ClassDetailContent';

//images
import bnr1 from './../../images/line2.png';
import clsimg1 from './../../images/classes/pic1.jpg';
import clsimg2 from './../../images/classes/pic2.jpg';
import clsimg3 from './../../images/classes/pic3.jpg';
import clsimg4 from './../../images/classes/pic4.jpg';
import clsimg5 from './../../images/classes/pic5.jpg';

function PostMedia(){
	return(
		<>
			<div className="dlab-post-meta">
				<ul>
					<li className="post-author">By Jone</li>
					<li className="post-comment"><i className="fa fa-comments"></i> 28</li>
				</ul>
			</div>	
				
		</>
	)
}
function LightGallery(){
	return(
		<>
			<SimpleReactLightbox>
				<SRLWrapper>
					{classDetails.map((data,index)=>(
						<li key={index}>
							<span className="check-km"><img src={data.image} alt="" /></span>
						</li>
					))}
				</SRLWrapper>	
			</SimpleReactLightbox>	
		</>
	)
}
const classDetails  = [
	
	{
		id: "PlayGroup",
		name: "PlayGroup",
		image: clsimg1,
		yearOld: "1.10+",
		classSize: "10-20",
		classTime: "8:45AM – 11:15AM 11:30AM – 2:00PM",
		courseDuration: "1 year",
		ProgramName: "Play Group / PLAY PODS -  1.10+ Years",
		desc1: "Our Play Group (Play Pods) Program is based on experiential and social learning and helps your child learn in a fun filled space through different activities.",
		desc2: "The activities are planned to promote growth in cognitive, literary, logical and social skills and help the toddler grow into an independent and confident individual while preparing for needs beyond pre-school.",
		desc3: "We provide developmental and age appropriate activities that help develop linguistic, logical-mathematical, bodily-kinaesthetic, musical, interpersonal, naturalistic skills.Introducing concepts like  me and my world, (Language, Math and Art), outdoor zones, role play, and nature zones that help the child to be prepared for future schooling.",
		
	},
	{
		id: "Nursery",
		name: "Nursery",
		image: clsimg4,
		yearOld: "2.10+",
		classSize: "10-20",
		classTime: "8:45AM – 11:45AM 11:30AM – 2:00PM",
		courseDuration: "1 year",
		ProgramName: "Nursery/ Learning PODS  2.10+ Years",
		desc1: "The Nursery program helps bridge the transition between the established elementary knowledge attained during Play Group (Play Pods) program and advanced learning abilities. The Learning Pods program focuses on building on the language ability, logical reasoning, numeric ability and scientific thought.",
		desc2: "We help them build a strong base in phonics, to improve their reading skills. Children learn to speak the alphabet in a sequential, rhyming manner and also write alphabets ",
	},
	{
		id: "LKG",
		name: "LKG",
		image: clsimg3,
		yearOld: "3.10+",
		classSize: "10-20",
		classTime: "8:45AM – 12:45PM",
		courseDuration: "1 year",
		ProgramName: "Junior Kindergarten / L.K.G 3.10+ Years",
		desc1: "Junior Kindergarten program focuses on the all-round development of a child. The curriculum revolves around five pillars of development: Language & Literacy, Math & Logic, Physical, Social & Emotional Development, General Awareness & Creative Expression.",
		desc2: "Literacy not only includes learning language and writing but also communicating. Math and Logic includes numbers as well as problem solving skills and cognitive development.we encourage children to express themselves through different media be it dance, music, art or drama.",
		desc3: "The areas of learning we teach are Math and number literacy, Language for reading, communicating and expression, Science and discovery in everyday life, Global awareness-social and cultural, Creativity for enriching our lives, better body control, awareness and finally a time management, a skill that is much required for formal schooling.",
	},
	{
		id: "UKG",
		name: "UKG",
		image: clsimg5,
		yearOld: "4.10+",
		classSize: "10-20",
		classTime: "8:45AM – 12:45PM",
		courseDuration: "1 year",
		ProgramName: "Senior Kindergarten / UKG 4.10+ Years",
		desc1: "Senior Kindergarten / UKG program with focus on Language, General science and Math skills. This program is designed to give children a strong foundation in the skills and academic excellence that will be needed to enter regular school.",
		desc2: "Le Dauphinz values teacher facilitated and child oriented curriculum with sequential instruction to ensure that children are prepared for the adventure of having a lifelong love for learning. Our UKG program is aligned with State, CBSE, ICSE and IB school standards, so that children will be prepared to confidently enter first grade.",
		desc3: "The program helps develop physical, personal, social, emotional skills, literature and language skills, logical-mathematical skills, creative expressive skills  and knowledge and understanding of the world. Our curriculum’s approach allows each child to explore Language, math, science, music, movement and field trips to meet our frameworks of learning.",
	},
	// {
	// 	id: "DayCare",
	// 	name: "DayCare",
	// 	image: gallery5,
	// 	yearOld: "0.6+",
	// 	classSize: "10-20",
	// 	classTime: "08:00 am - 12:30 pm",
	// 	courseDuration: "1 year",
	// 	ProgramName: "DayCare 0.6+ Years",
	// 	desc1: "",
	// },
	
];

class ClassesDetail extends Component{
	state = {
		classObject: {

		}
	}
	componentDidMount() {
		// let classId = this.props.params.id;
		// console.log("classId: ", classId)
		console.log(window.location.pathname);
		let path = window.location.pathname
		let res = path.split("/")
		let classId = res[res.length - 1];
		console.log("classId: ", classId);
		let classObject 
		for (const cl of classDetails) {
			if (cl.id == classId) {
				classObject = cl 
				break;
			}
		
		}
		this.setState({classObject:classObject}, this.forceUpdate())
	}
	render(){
		return(
			<Fragment>	
				<Header />
				<div className="page-content" >
					<PageTitle motherMenu={this.state.classObject.name} activeMenu="Classes Detail" />
					<div className="content-block">
						<div className="section-full bg-white content-inner" style={{backgroundImage:"url("+ bnr1 +")", backgroundSize:"contain",backgroundRepeat: "no-repeat",backgroundPosition: "center"}}>
							<div className="container">
								<div className="row">
									<div className="col-lg-8 col-md-12 col-sm-12 m-b15">
										<ClassDetailContent
										 	classObject={this.state.classObject}
										/>

									</div>
									<div className="col-lg-4 col-md-12 col-sm-12">
										<div className="widget">
											<h5 className="widget-title">{this.state.classObject.name} <span>Details</span> </h5>
											{/* <div className="search-bx style-1">
												<form role="search" method="post">
													<div className="input-group">
														<input name="text" className="form-control" placeholder="Enter your keywords..." type="text" />
														<span className="input-group-btn">
															<button type="submit" className="fa fa-search text-primary"></button>
														</span> 
													</div>
												</form>
											</div> */}
										</div>
										<div className="details-tbl widget">
											<ul className="class-details">
												<li>
													<div className="name"><i className="la la-clock-o"></i>Program</div>
													<div className="info">{this.state.classObject.name}</div>
												</li>
												<li>
													<div className="name"><i className="la la-calendar-check-o"></i>Years</div>
													<div className="info">{this.state.classObject.yearOld}</div>
												</li>
												<li>
													<div className="name"><i className="la la-arrows"></i>Class Size</div>
													<div className="info">{this.state.classObject.classSize}</div>
												</li>
												{/* <li>
													<div className="name"><i className="la la-hourglass"></i>Carry Time</div>
													<div className="info">{this.state.classObject.carryTime}</div>
												</li> */}
												<li>
													<div className="name"><i className="la la-history"></i>Coures Duration</div>
													<div className="info">{this.state.classObject.courseDuration}</div>
												</li>
												<li>
													<div className="name"><i className="la la-clock-o"></i>Class Time</div>
													<div className="info">{this.state.classObject.classTime}</div>
												</li>
												<li>
													<div className="name"><i className="la la-star-o"></i>Rating</div>
													<div className="info rating">
													<i className="fa fa-star" aria-hidden="true"></i>
													<i className="fa fa-star" aria-hidden="true"></i>
													<i className="fa fa-star" aria-hidden="true"></i>
													<i className="fa fa-star" aria-hidden="true"></i>
													<i className="fa fa-star" aria-hidden="true"></i>
													</div>
												</li>
												{/* <li>
													<div className="name"><i className="la la-user"></i>Tution Free</div>
													<div className="info">$ 250.00</div>
												</li> */}
											</ul>
										</div>

										{/* <div className="widget recent-posts-entry">
											<h5 className="widget-title">Recent Posts</h5>
											<div className="widget-post-bx">
												<div className="widget-post clearfix">
													<div className="dlab-post-media"> <img src={recent1} width="200" height="143" alt="" /> </div>
													<div className="dlab-post-info">
														<div className="dlab-post-header">
															<h6 className="post-title"><Link to={"/blog-details"}>Reasons Why You Cannot Learn Kids Education Well.</Link></h6>
														</div>
														<PostMedia />
													</div>
												</div>
												<div className="widget-post clearfix">
													<div className="dlab-post-media"> <img src={recent2} width="200" height="143" alt="" /> </div>
													<div className="dlab-post-info">
														<div className="dlab-post-header">
															<h6 className="post-title"><Link to={"/blog-details"}>Small But Important Things To Observe In Kids.</Link></h6>
														</div>
														<PostMedia />
													</div>
												</div>
											</div>
										</div> */}
										{/* <div className="widget widget_gallery gallery-grid-4">
											<h5 className="widget-title">Our services</h5>
											<ul id="lightgallery">
												<LightGallery />	
											</ul>
										</div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</Fragment>	
		)	
	}
}

export {LightGallery}
export default ClassesDetail;